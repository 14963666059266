const replaceAt = (text, index, replacement) => {
  if (!text) {
    return "";
  }
  if (index >= text.length) {
    return text.valueOf();
  }

  const chars = text.split("");
  chars[index] = replacement;
  return chars.join("");
};

function sort(pictures) {
  return pictures.sort((pictureA, pictureB) => {
    const dateA = new Date(
      replaceAt(replaceAt(pictureA.imageDate, 4, "-"), 7, "-")
    );
    const dateB = new Date(
      replaceAt(replaceAt(pictureB.imageDate, 4, "-"), 7, "-")
    );
    if (dateA < dateB) {
      return -1;
    }
    if (dateA > dateB) {
      return 1;
    }
    return 0;
  });
}

export { sort };
