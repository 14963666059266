// import styles
import "./App.css";
import { useState, useEffect } from "react";
import api from "./api.js";
import PicturesComponent from "./picturesComponent";
import ClipLoader from "react-spinners/ClipLoader";
import slugify from "react-slugify";

const deviceWidth = window.screen.width;

function App() {
  const [data, setData] = useState(null);

  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    async function fetchData() {
      const response = await api.getData();
      setData(response.data);
    }
    fetchData();
  }, []);

  if (data === null) {
    return (
      <div className="spinner-container">
        <div className="spinner-items">
          <ClipLoader
            color="black"
            loading
            size={150}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      </div>
    );
  }

  return (
    <div className="App">
      <div className="header">
        <h1 className="main-title">From Our Eyes</h1>
        <p className="main-description">
          We are Julie & Pierre-Gilles, and we've been traveling the world for
          the past years. This website is an album of all our trips !
        </p>
      </div>
      <div className="fab" onClick={scrollToTop}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M12 4.44L6.12 10.3a1 1 0 0 0 1.41 1.42L12 7.4l4.47 4.32a1 1 0 1 0 1.42-1.42L12 4.44z" />
        </svg>
      </div>

      <div className="trip-list">
        {data.map((trip) => {
          return (
            <a href={"#" + slugify(trip.title + trip.date)} className="badge">
              {trip.title}
            </a>
          );
        })}
      </div>
      <PicturesComponent data={data} deviceWidth={deviceWidth} />
    </div>
  );
}

export default App;
