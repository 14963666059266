/* eslint-disable jsx-a11y/anchor-is-valid */

// import styles
import "./App.css";

import { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { sort } from "./sort";

function PicturesComponent({
  data,
  isLoggedIn,
  deleteTrip,
  addMedia,
  deleteMedia,
  tripIsLoading,
}) {
  const [editMode, setEditMode] = useState(false);

  return (
    <div>
      {data.map((trip) => {
        return (
          <div key={trip.id} className="trip-column">
            <div className="destination-title-div">
              <div className="title-row">
                <div className="destination-title-text">
                  {trip.title.toUpperCase()}
                </div>
                {isLoggedIn && (
                  <>
                    <img
                      onClick={() => deleteTrip(trip)}
                      alt="delete"
                      width={24}
                      style={{ marginLeft: "8px" }}
                      src={require("./assets/trash.png")}
                    />
                    <img
                      onClick={() => setEditMode(!editMode)}
                      alt="edit"
                      width={24}
                      style={{ marginLeft: "8px" }}
                      src={require("./assets/edit.png")}
                    />
                    <FileUploader
                      handleChange={(files) => addMedia(files, trip)}
                      multiple={true}
                      name="file"
                      types={["JPG", "JPEG", "PNG", "MP4", "MOV"]}
                    >
                      <div className="upload-container">
                        <div fontSize={14}>+</div>
                      </div>
                    </FileUploader>
                  </>
                )}
              </div>
              <div className="destination-subtitle-text">
                {trip.date.toUpperCase()}
              </div>
              {tripIsLoading.find((t) => t.tripId === trip.id) && (
                <span className="loading-in-progress">
                  Loading in progress - Medias added :{" "}
                  {tripIsLoading.find((t) => t.tripId === trip.id).progress}/
                  {tripIsLoading.find((t) => t.tripId === trip.id).total}{" "}
                </span>
              )}
            </div>

            <div className="gallery-view">
              {sort(trip.pictures).map((media) => {
                if (!media.url) {
                  return;
                }
                if (
                  media.url &&
                  (media.url.endsWith(".mp4") || media.url.endsWith(".mov"))
                ) {
                  return editMode ? (
                    <div key={media.url} className="media-column">
                      <a
                        href={media.url}
                        data-lg-size="1280-720"
                        data-src={media.url}
                        data-poster={media.thumbnail}
                        data-sub-html={media.sub}
                      >
                        <img
                          alt={media.alt}
                          className="thumbnail"
                          src={media.thumbnail}
                          loading="lazy"
                        />
                      </a>
                      <div
                        className="delete-media-div"
                        onClick={() => {
                          deleteMedia(media, trip);
                        }}
                      >
                        <img
                          alt="delete"
                          width={24}
                          src={require("./assets/trash.png")}
                        />
                      </div>
                    </div>
                  ) : (
                    <a
                      key={media.url}
                      className="include-in-gallery"
                      href={media.url}
                      data-lg-size="1280-720"
                      data-src={media.url}
                      data-poster={media.thumbnail}
                      data-sub-html={media.sub}
                    >
                      <img
                        alt={media.alt}
                        className="thumbnail"
                        src={media.thumbnail}
                        loading="lazy"
                      />
                    </a>
                  );
                } else {
                  return editMode ? (
                    <div key={media.url} className="media-column">
                      <a data-src={media.url} data-sub-html={media.sub}>
                        <img
                          alt={media.alt}
                          className="thumbnail"
                          src={media.thumbnail}
                          loading="lazy"
                        />
                      </a>

                      <div
                        className="delete-media-div"
                        onClick={() => {
                          deleteMedia(media, trip);
                        }}
                      >
                        <img
                          alt="delete"
                          width={24}
                          src={require("./assets/trash.png")}
                        />
                      </div>
                    </div>
                  ) : (
                    <a
                      key={media.url}
                      data-src={media.url}
                      data-sub-html={media.sub}
                      className="include-in-gallery"
                    >
                      <img
                        alt={media.alt}
                        className="thumbnail"
                        src={media.thumbnail}
                        loading="lazy"
                      />
                    </a>
                  );
                }
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PicturesComponent;
