/* eslint-disable jsx-a11y/anchor-is-valid */

import Promise from "bluebird";
import slugify from "react-slugify";
import Resizer from "react-image-file-resizer";
import ExifReader from "exifreader";

// import styles
import "./Admin.css";

import { useState, useEffect } from "react";

import { v4 as uuidv4 } from "uuid";

import api from "./api.js";
import PicturesComponent from "./AdminPicturesComponent";

const getImageSize = (media) => {
  return new Promise((resolve) => {
    const img = new Image();
    const objectUrl = window.URL.createObjectURL(media);
    img.onload = function () {
      window.URL.revokeObjectURL(objectUrl);
      resolve({ width: this.width, height: this.height });
    };
    img.src = objectUrl;
  });
};

const getMediaName = (originalName) => {
  const mediaName = originalName.substr(0, originalName.lastIndexOf("."));
  const mediaExtension = /[.]/.exec(originalName)
    ? /[^.]+$/.exec(originalName)
    : undefined;

  let name = mediaName + Math.random().toString(36).slice(2);
  name = slugify(name);
  name = name.concat("." + mediaExtension);
  return name;
};

function Admin() {
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(api.isLoggedIn());
  const [codeInput, setCodeInput] = useState("");
  const [data, setData] = useState([]);
  const [newAlbumTitle, setNewAlbumTitle] = useState("");
  const [newAlbumDate, setNewAlbumDate] = useState("");

  const [tripIsLoading, setTripIsLoading] = useState([]); //{tripId: 0,progress: 0,total: 0,}
  const [saveDeletion, setSaveDeletion] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await api.getData();
      setData(response.data);
    }
    fetchData();
  }, []);

  const logInUser = () => {
    if (codeInput !== "") {
      api.login(codeInput);
      setIsLoggedIn(true);
    }
  };

  const createTrip = () => {
    let newTrips = [...data];

    let folderName = newAlbumTitle + Math.random().toString(36).slice(2);
    folderName = slugify(folderName);

    newTrips.unshift({
      title: newAlbumTitle,
      date: newAlbumDate,
      pictures: [],
      folder: folderName,
      id: uuidv4(),
    });

    setData(newTrips);
    api.updateData(newTrips);
  };

  const deleteTrip = (trip) => {
    setSaveDeletion(true);
    let newTrips = [...data];
    const tripIndex = newTrips.indexOf(trip);
    newTrips.splice(tripIndex, 1);
    setData(newTrips);
  };

  const saveData = () => {
    api.updateData(data);
    setSaveDeletion(false);
  };

  const deleteMedia = async (media, trip) => {
    let newData = [...data];
    let newTripData = newData.find((t) => t.id === trip.id);
    let newTripDataPicture = [...newTripData.pictures];
    var indexMedia = newTripDataPicture.indexOf(media);
    if (indexMedia !== -1) {
      newTripDataPicture.splice(indexMedia, 1);
    }
    newTripData = { ...newTripData, pictures: newTripDataPicture };
    var index = newData.indexOf(trip);

    if (index !== -1) {
      newData[index] = newTripData;
    }

    setData(newData);
    await api.updateData(newData);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1300,
        1300,
        "JPEG",
        60,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  function getVideoCover(file, seekTo = 0.0) {
    return new Promise((resolve, reject) => {
      // load the file to a video player
      const videoPlayer = document.createElement("video");
      videoPlayer.setAttribute("src", URL.createObjectURL(file));
      videoPlayer.load();
      videoPlayer.addEventListener("error", (ex) => {
        reject("error when loading video file", ex);
      });
      // load metadata of the video to get video duration and dimensions
      videoPlayer.addEventListener("loadedmetadata", () => {
        // seek to user defined timestamp (in seconds) if possible
        if (videoPlayer.duration < seekTo) {
          reject("video is too short.");
          return;
        }
        // delay seeking or else 'seeked' event won't fire on Safari
        setTimeout(() => {
          videoPlayer.currentTime = seekTo;
        }, 200);
        // extract video thumbnail once seeking is complete
        videoPlayer.addEventListener("seeked", () => {
          console.log("video is now paused at %ss.", seekTo);
          // define a canvas to have the same dimension as the video
          const canvas = document.createElement("canvas");
          canvas.width = videoPlayer.videoWidth;
          canvas.height = videoPlayer.videoHeight;
          // draw the video frame to canvas
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
          // return the canvas image as a blob
          ctx.canvas.toBlob(
            (blob) => {
              resolve(blob);
            },
            "image/jpeg",
            0.75 /* quality */
          );
        });
      });
    });
  }

  const addMedia = async (files, trip) => {
    let newData = [...data];
    let newTripData = newData.find((t) => t.id === trip.id);

    let itemsUploaded = 0;

    // Create loading state
    let newTripLoading = {
      tripId: trip.id,
      progress: 0,
      total: files.length,
    };
    let newTrips = [...tripIsLoading];

    await newTrips.push(newTripLoading);

    setTripIsLoading(newTrips);

    // Upload with concurrency
    await Promise.map(
      files,
      async (mediaFullSize) => {
        let media, response, width, height, imageDate;
        try {
          media = await resizeFile(mediaFullSize);
          const size = await getImageSize(media);
          const tags = await ExifReader.load(mediaFullSize);
          imageDate = tags["DateTimeOriginal"].description;
          width = size.width;
          height = size.height;
          const arrayBuffer = await media.arrayBuffer();
          const blob = new Blob([new Uint8Array(arrayBuffer)], {
            type: media.type,
          });
          const name = getMediaName(media.name);
          response = await api.uploadImage(trip.folder, name, blob);
        } catch (err) {
          console.log(err);
        }

        if (
          mediaFullSize.name.endsWith(".mp4") ||
          mediaFullSize.name.endsWith(".mov")
        ) {
          media = mediaFullSize;
          const thumbnailBlob = await getVideoCover(mediaFullSize);
          const thumbnailName = mediaFullSize.name + ".jpg";
          const thumbnailNameWithRandom = getMediaName(thumbnailName);
          const videoNameWithRandom = getMediaName(mediaFullSize.name);
          const size = await getImageSize(thumbnailBlob);
          width = size.width;
          height = size.height;
          // Upload video
          response = await api.uploadImage(
            trip.folder,
            videoNameWithRandom,
            mediaFullSize
          );
          // Upload thumbnail
          const responseThumbnail = await api.uploadImage(
            trip.folder,
            thumbnailNameWithRandom,
            thumbnailBlob
          );
          let newTripDataPicture = [...newTripData.pictures];
          const newMedia = {
            url: response,
            thumbnail: responseThumbnail,
            alt: mediaFullSize.name,
            width,
            height,
            sub: "",
          };
          newTripDataPicture.push(newMedia);
          newTripData = { ...newTripData, pictures: newTripDataPicture };
        } else {
          let newTripDataPicture = [...newTripData.pictures];
          const newMedia = {
            url: response,
            thumbnail: response,
            alt: media.name,
            width,
            height,
            imageDate,
            sub: "",
          };
          newTripDataPicture.push(newMedia);
          newTripData = { ...newTripData, pictures: newTripDataPicture };
        }

        itemsUploaded++;

        //update loading progress
        const indexLoading = newTrips.findIndex((t) => t.tripId === trip.id);

        newTripLoading = {
          tripId: trip.id,
          progress: itemsUploaded,
          total: files.length,
        };

        if (indexLoading !== -1) {
          newTrips[indexLoading] = newTripLoading;
        }
        setTripIsLoading([...newTrips]);
      },
      { concurrency: 4 }
    );

    const index = newData.indexOf(trip);

    if (index !== -1) {
      newData[index] = newTripData;
    }

    setData(newData);
    await api.updateData(newData);

    // Remove progress bar
    const indexToRemove = newTrips.findIndex((t) => t.tripId === trip.id);
    if (indexToRemove !== -1) {
      newTrips.splice(indexToRemove, 1);
      newTrips = [...newTrips];
    }

    setTripIsLoading(newTrips);
  };

  return (
    <div className="App">
      <div className="destination-title-div">
        <button
          className="button"
          onClick={() => {
            setIsAdminOpen(!isAdminOpen);
          }}
        >
          Add new album !
        </button>
        {saveDeletion && (
          <button
            className="button"
            onClick={() => {
              saveData();
            }}
          >
            Save changes !
          </button>
        )}
      </div>
      {isAdminOpen && (
        <div className="destination-title-div">
          {!isLoggedIn && (
            <>
              <label for="name">Secret Code:</label>
              <div className="row-input">
                <input
                  type="text"
                  id="code"
                  value={codeInput}
                  onChange={(e) => setCodeInput(e.target.value)}
                  name="code"
                  required
                />
                <input
                  type="submit"
                  className="small-button"
                  value="Login"
                  onClick={logInUser}
                />
              </div>
            </>
          )}
          {isLoggedIn && (
            <div className="destination-title-div">
              <div className="row-input">
                <div className="col-input">
                  <label for="titleName"> Trip title </label>
                  <input
                    type="text"
                    style={{ marginTop: "4px" }}
                    id="titleName"
                    value={newAlbumTitle}
                    onChange={(e) => setNewAlbumTitle(e.target.value)}
                    name="code"
                    required
                  />
                </div>
                <div className="col-input">
                  <label for="titleDate"> Trip date </label>
                  <input
                    type="text"
                    style={{ marginTop: "4px" }}
                    id="titleDate"
                    value={newAlbumDate}
                    onChange={(e) => setNewAlbumDate(e.target.value)}
                    name="code"
                    required
                  />
                </div>
                <input
                  type="button"
                  className="small-button"
                  value="Create a new album"
                  onClick={createTrip}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <PicturesComponent
        data={data}
        isLoggedIn={isLoggedIn}
        deleteTrip={deleteTrip}
        addMedia={addMedia}
        deleteMedia={deleteMedia}
        tripIsLoading={tripIsLoading}
      />
    </div>
  );
}

export default Admin;
