import { memo } from "react";
import "./PictureList.css";

const ImageWithMemo = memo(function Image({ src, alt, width, height }) {
  return (
    <img
      alt={alt}
      loading="lazy"
      src={src}
      style={{
        aspectRatio: `${width} / ${height}`,
      }}
    />
  );
});

const ImageGrid = ({ columns }) => (
  <div className="row">
    {columns.map((column) => (
      <div className="column">
        {column.map((media) => {
          if (!media.url) {
            return;
          }
          if (media.url.endsWith(".mp4") || media.url.endsWith(".mov")) {
            return (
              <a
                key={media.url}
                className="include-in-gallery image-item"
                href={media.url}
                data-lg-size="1280-720"
                data-src={media.url}
                data-poster={media.thumbnail}
                data-sub-html={media.sub}
              >
                <ImageWithMemo alt={media.alt} src={media.thumbnail} />
              </a>
            );
          } else if (media.url) {
            return (
              <a
                key={media.url}
                href={media.url}
                data-src={media.url}
                data-sub-html={media.sub}
                className="include-in-gallery image-item"
              >
                <ImageWithMemo
                  alt={media.alt}
                  src={media.thumbnail}
                  width={media.width}
                  height={media.height}
                />
              </a>
            );
          }
        })}
      </div>
    ))}
  </div>
);

export default ImageGrid;
