/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";

const LOCAL_STORAGE_ADMIN_AUTH = "admin_auth_token";

const BACKEND_URL = "https://travel-pictures.hello2951.workers.dev";
const BUCKET_URL = "https://pictures-storage.pierregillesleymarie.com";

function login(authHeader) {
  localStorage.setItem(LOCAL_STORAGE_ADMIN_AUTH, authHeader);
}

function isLoggedIn() {
  const authHeader = localStorage.getItem(LOCAL_STORAGE_ADMIN_AUTH);
  if (!authHeader) {
    return false;
  }
  return true;
}

async function getData() {
  return axios.get(`${BACKEND_URL}/data`);
}

/**
 *
 * @param {string} folder - The name of the folder where to put the file into
 * @param {string} filename - The name of the file
 * @param {*} data -
 * @returns
 */
async function uploadFile(folder, filename, data) {
  const authHeader = localStorage.getItem(LOCAL_STORAGE_ADMIN_AUTH);
  if (!authHeader) {
    throw new Error("Should login first");
  }
  return axios.request({
    method: "put",
    url: `${BACKEND_URL}/upload`,
    params: {
      folder,
      filename,
    },
    headers: {
      "Content-Type": "image/jpeg",
      authorization: authHeader,
    },
    data: data,
  });
}

async function updateData(data) {
  await uploadFile("data", "data.json", JSON.stringify(data));
}

async function uploadImage(folder, filename, blob) {
  await uploadFile(folder, filename, blob);
  return `${BUCKET_URL}/${folder}/${filename}`;
}

export default {
  isLoggedIn,
  login,
  getData,
  updateData,
  uploadImage,
};
