/* eslint-disable jsx-a11y/anchor-is-valid */
import LightGallery from "lightgallery/react";
import { memo } from "react";
import isEqual from "react-fast-compare";

// import styles
import "./App.css";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";
import "lightgallery/css/lg-autoplay.css";
import "lightgallery/css/lg-fullscreen.css";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import lgAutoplay from "lightgallery/plugins/autoplay";
import lgFullScreen from "lightgallery/plugins/fullscreen";

import ImageGrid from "./ImageGrid";
import { sort } from "./sort";
import slugify from "react-slugify";

const plugins = [lgThumbnail, lgZoom, lgVideo, lgAutoplay, lgFullScreen];
const LightGalleryWithMemo = memo(LightGallery, isEqual);

function findSmallestArray(currentHeightArray) {
  let smallest = Infinity;
  let smallestIndex = 0;
  currentHeightArray.forEach((currentHeight, index) => {
    if (currentHeight < smallest) {
      smallest = currentHeight;
      smallestIndex = index;
    }
  });
  return smallestIndex;
}

function splitArrayIntoMultipleArray(array, size) {
  const currentHeightArray = [];
  const result = [];

  for (let i = 0; i < size; i++) {
    result.push([]);
    currentHeightArray.push(0);
  }

  for (let i = 0; i < array.length; i++) {
    const smallestIndex = findSmallestArray(currentHeightArray);
    result[smallestIndex].push(array[i]);
    currentHeightArray[smallestIndex] += array[i].height || 1300;
  }

  return result;
}

function PicturesComponent({ data, deviceWidth }) {
  console.log("Rendering pictures component");

  const columnNumber = deviceWidth >= 800 ? 8 : 3;

  return (
    <LightGalleryWithMemo
      elementClassNames=""
      speed={500}
      plugins={plugins}
      selector=".include-in-gallery"
    >
      <div className="container">
        {data.map((trip) => {
          return (
            <div key={trip.id} className="trip-column">
              <div
                id={slugify(trip.title + trip.date)}
                className="destination-title-div"
              >
                <div className="title-row">
                  <div className="destination-title-text">
                    {trip.title.toUpperCase()}
                  </div>
                </div>
                <div className="destination-subtitle-text">
                  {trip.date.toUpperCase()}
                </div>
              </div>
              <div>
                <ImageGrid
                  columns={splitArrayIntoMultipleArray(
                    sort(trip.pictures),
                    columnNumber
                  )}
                />
              </div>
            </div>
          );
        })}
      </div>
    </LightGalleryWithMemo>
  );
}

export default PicturesComponent;
